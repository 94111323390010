
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "path": "/home",
    "label": "Home",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/tbwarga",
    "label": "Tb Warga",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  }
],
	tbwargalistheader: [
  {
    "label": "",
    "align": "left",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  },
  {
    "label": "No KK",
    "align": "left",
    "sortable": true,
    "name": "no_kk",
    "field": "no_kk"
  },
  {
    "label": "No KTP",
    "align": "left",
    "sortable": true,
    "name": "no_ktp",
    "field": "no_ktp"
  },
  {
    "label": "Nama Kepala Keluarga",
    "align": "left",
    "sortable": true,
    "name": "nama_kk",
    "field": "nama_kk"
  }
],

    exportFormats: {
        print: {
			label: 'Print',
			color: 'blue',
            icon: 'print',
            id: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			color: 'red',
            icon: 'picture_as_pdf',
            id: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
			color: 'green',
            icon: 'grid_on',
            id: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
			color: 'teal',
            icon: 'grid_on',
            id: 'csv',
            ext: 'csv',
        },
    },
    
}